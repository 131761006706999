import { Box, ListItemButton, Stack } from '@mui/material';
import { alpha, css } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { NAV } from './config-layout';
import Scrollbar from '../component/scrollbar/scrollbar';
import { usePathname } from '../routes/hooks';
import styled from '@emotion/styled';

export default function Nav() {
    const pathname = usePathname().split('/')[1];

    // const active = item.path === pathname;

    return (
        <>
            <Box
                sx={{
                    flexShrink: { lg: 0 },
                    width: { lg: NAV.WIDTH },
                }}
            >
                <Box
                    width={280}
                    height={'100%'}
                    position={'fixed'}
                    sx={{
                        position: 'fixed',
                        borderRight: '1px solid #e4e4e4',
                    }}
                >
                    <Scrollbar
                        sx={{
                            height: 1,
                            '& .simplebar-content': {
                                height: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor:"#f9fafb"
                            },
                        }}
                    >
                        <Box
                            sx={{
                                my: 3,
                                mx: 1.5,
                                py: 2,
                                px: 2.5,
                                fontSize: 20,
                                display: 'flex',
                                borderRadius: 1.5,
                                alignItems: 'center',
                                // bgcolor: (theme) =>
                                //     alpha(theme.palette.grey[500], 0.12),
                            }}
                        >
                            <Link
                                to={'/'}
                                style={{
                                    textDecoration: 'none',
                                    color: '#333',
                                }}
                            >
                                HBMP 부킹 관리자
                            </Link>
                        </Box>
                        <Stack
                            component={'nav'}
                            spacing={0.5}
                            sx={{
                                px: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }}
                        >
                            <Stack>
                                <ParentComp title="연단체 부킹" isFirst={true}>
                                    <Link
                                        to={'yearBookingList'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 44,
                                                borderRadius: 0.75,
                                                // typography: 'body2',
                                                color: 'text.secondary',
                                                textTransform: 'capitalize',
                                                fontWeight: 'fontWeightMedium',
                                                ...('yearBookingList' ===
                                                    pathname && {
                                                    color: 'primary.main',
                                                    fontWeight:
                                                        'fontWeightSemiBold',
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.08
                                                        ),
                                                    '&:hover': {
                                                        bgcolor: (theme) =>
                                                            alpha(
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                                0.16
                                                            ),
                                                    },
                                                }),
                                            }}
                                        >
                                            <Box component={'span'}>
                                                신청내역
                                            </Box>
                                        </ListItemButton>
                                    </Link>
                                    <Link
                                        to={'yearBookingRoundingList'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 44,
                                                borderRadius: 0.75,
                                                // typography: 'body2',
                                                color: 'text.secondary',
                                                textTransform: 'capitalize',
                                                fontWeight: 'fontWeightMedium',
                                                ...('yearBookingRoundingList' ===
                                                    pathname && {
                                                    color: 'primary.main',
                                                    fontWeight:
                                                        'fontWeightSemiBold',
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.08
                                                        ),
                                                    '&:hover': {
                                                        bgcolor: (theme) =>
                                                            alpha(
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                                0.16
                                                            ),
                                                    },
                                                }),
                                            }}
                                        >
                                            <Box component={'span'}>
                                                매월 라운드 일정
                                            </Box>
                                        </ListItemButton>
                                    </Link>
                                </ParentComp>
                                <ParentComp title="할인 부킹">
                                    <Link
                                        to={'bookingdiscount'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 44,
                                                borderRadius: 0.75,
                                                // typography: 'body2',
                                                color: 'text.secondary',
                                                textTransform: 'capitalize',
                                                fontWeight: 'fontWeightMedium',
                                                ...('bookingdiscount' ===
                                                    pathname && {
                                                    color: 'primary.main',
                                                    fontWeight:
                                                        'fontWeightSemiBold',
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.08
                                                        ),
                                                    '&:hover': {
                                                        bgcolor: (theme) =>
                                                            alpha(
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                                0.16
                                                            ),
                                                    },
                                                }),
                                            }}
                                        >
                                            <Box component={'span'}>
                                                할인부킹 관리
                                            </Box>
                                        </ListItemButton>
                                    </Link>
                                    <Link
                                        to={'bookingdiscountrsvps'}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 44,
                                                borderRadius: 0.75,
                                                // typography: 'body2',
                                                color: 'text.secondary',
                                                textTransform: 'capitalize',
                                                fontWeight: 'fontWeightMedium',
                                                ...('bookingdiscountrsvps' ===
                                                    pathname && {
                                                    color: 'primary.main',
                                                    fontWeight:
                                                        'fontWeightSemiBold',
                                                    bgcolor: (theme) =>
                                                        alpha(
                                                            theme.palette
                                                                .primary.main,
                                                            0.08
                                                        ),
                                                    '&:hover': {
                                                        bgcolor: (theme) =>
                                                            alpha(
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                                0.16
                                                            ),
                                                    },
                                                }),
                                            }}
                                        >
                                            <Box component={'span'}>
                                                할인부킹 예약 관리
                                            </Box>
                                        </ListItemButton>
                                    </Link>
                                </ParentComp>
                            </Stack>
                        </Stack>
                        <Stack sx={{ backgroundColor: '#f4f6f8', padding: 1 }}>
                            <Link
                                to={'golfinfo'}
                                style={{ textDecoration: 'none' }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 44,
                                        borderRadius: 0.75,
                                        // typography: 'body2',
                                        color: 'text.secondary',
                                        textTransform: 'capitalize',
                                        fontWeight: 'fontWeightMedium',
                                        ...('golfinfo' === pathname && {
                                            color: 'primary.main',
                                            fontWeight: 'fontWeightSemiBold',
                                            bgcolor: (theme) =>
                                                alpha(
                                                    theme.palette.primary.main,
                                                    0.08
                                                ),
                                            '&:hover': {
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.16
                                                    ),
                                            },
                                        }),
                                    }}
                                >
                                    <Box component={'span'}>골프장 관리</Box>
                                </ListItemButton>
                            </Link>
                            <Link
                                to={'golfacount'}
                                style={{ textDecoration: 'none' }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 44,
                                        borderRadius: 0.75,
                                        // typography: 'body2',
                                        color: 'text.secondary',
                                        textTransform: 'capitalize',
                                        fontWeight: 'fontWeightMedium',
                                        ...('golfacount' === pathname && {
                                            color: 'primary.main',
                                            fontWeight: 'fontWeightSemiBold',
                                            bgcolor: (theme) =>
                                                alpha(
                                                    theme.palette.primary.main,
                                                    0.08
                                                ),
                                            '&:hover': {
                                                bgcolor: (theme) =>
                                                    alpha(
                                                        theme.palette.primary
                                                            .main,
                                                        0.16
                                                    ),
                                            },
                                        }),
                                    }}
                                >
                                    <Box component={'span'}>
                                        골프장 계정관리
                                    </Box>
                                </ListItemButton>
                            </Link>
                        </Stack>
                    </Scrollbar>
                </Box>
            </Box>
        </>
    );
}

const ParentComp = (props) => {
    return (
        <PareantStack isFirst={props.isFirst}>
            <div className="title">{props.title}</div>
            <div className="children">{props.children}</div>
        </PareantStack>
    );
};
const PareantStack = styled(Stack)`
    ${(p) => {
        if (p.isFirst) {
            return css`
                border-top: 1px solid gray;
            `;
        }
        return css``;
    }}
    border-bottom: 1px solid gray;
    padding: 10px 0px;
    .title {
        padding-left: 15px;
        color: ${(p) => p.theme.palette.text.secondary};
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
`;
